"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.ChildrenCollection = void 0;
var ChildrenCollection = /** @class */ (function () {
    function ChildrenCollection() {
        this.components = [];
        this.found = null;
        this.allFound = [];
    }
    ChildrenCollection.prototype.GetByTestAttr = function (id) {
        return this.All.find(function (x) { return x.GetAttribute("data-test") == id; });
    };
    ChildrenCollection.prototype.SearchByTestAttr = function (id) {
        var _this = this;
        this.found = null;
        this.components.forEach(function (c) {
            return _this.FindInComponentByTestAttr(c, id);
        });
        return this.found;
    };
    Object.defineProperty(ChildrenCollection.prototype, "All", {
        get: function () {
            return this.components;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChildrenCollection.prototype, "Count", {
        get: function () {
            return this.components.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChildrenCollection.prototype, "Clone", {
        get: function () {
            return __spreadArray([], this.components, true);
        },
        enumerable: false,
        configurable: true
    });
    ChildrenCollection.prototype.Add = function (component) {
        this.components.push(component);
    };
    ChildrenCollection.prototype.Has = function (component) {
        return this.components.includes(component);
    };
    ChildrenCollection.prototype.Remove = function (component) {
        return this.components.Remove(component);
    };
    ChildrenCollection.prototype.FindInObj = function (component, type) {
        var _this = this;
        if (component instanceof type) {
            this.found = component;
            return this.found;
        }
        else
            component.Children.All.forEach(function (c) {
                if (c instanceof type) {
                    _this.found = c;
                    return _this.found;
                }
                else
                    _this.FindInObj(c, type);
            });
    };
    ChildrenCollection.prototype.FindInComponentByTestAttr = function (component, id) {
        var _this = this;
        if (component.GetAttribute("data-test") == id) {
            this.found = component;
            return this.found;
        }
        else
            component.Children.All.forEach(function (c) {
                if (c.GetAttribute("data-test") == id) {
                    _this.found = c;
                    return _this.found;
                }
                else
                    _this.FindInComponentByTestAttr(c, id);
            });
    };
    ChildrenCollection.prototype.FindFirstOfType = function (type) {
        var _this = this;
        this.found = null;
        this.components.forEach(function (c) {
            return _this.FindInObj(c, type);
        });
        return this.found;
    };
    ChildrenCollection.prototype.FindAllInObj = function (component, type) {
        var _this = this;
        if (component instanceof type) {
            this.allFound.push(component);
        }
        else
            component.Children.All.forEach(function (c) {
                if (c instanceof type) {
                    _this.allFound.push(c);
                }
                else
                    _this.FindAllInObj(c, type);
            });
    };
    ChildrenCollection.prototype.FindAllOfType = function (type) {
        var _this = this;
        this.allFound = [];
        this.components.forEach(function (c) {
            return _this.FindAllInObj(c, type);
        });
        return this.allFound;
    };
    ChildrenCollection.prototype.toString = function () {
        return this.components.map(function (x) { return "".concat(x.ID); });
    };
    return ChildrenCollection;
}());
exports.ChildrenCollection = ChildrenCollection;
