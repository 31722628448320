"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.RadioSwitchesBuilder = void 0;
var RefComponentBase_1 = require("../../../Core/RefComponentBase");
var RadioSwitchesBuilder = /** @class */ (function (_super) {
    __extends(RadioSwitchesBuilder, _super);
    function RadioSwitchesBuilder(ref) {
        var _this = _super.call(this, "div", ref) || this;
        _this.switches = {};
        _this.classWhenActive = {};
        return _this;
    }
    RadioSwitchesBuilder.prototype.Refresh = function () {
        var _this = this;
        this.switches.Keys().forEach(function (activator) {
            var cmp = _this.switches[activator];
            var classWhenActive = _this.classWhenActive[activator];
            if (_this.ref.value === activator)
                cmp.AddClass(classWhenActive);
            else
                cmp.RemoveClass(classWhenActive);
        });
    };
    RadioSwitchesBuilder.prototype.AddSwitch = function (activator, cmp, cssClassWhenActive) {
        var _this = this;
        this.switches[activator] = cmp;
        this.classWhenActive[activator] = cssClassWhenActive;
        cmp.OnClick(function () { return _this.ref.value = activator; });
        if (this.ref.value == activator)
            cmp.AddClass(cssClassWhenActive);
        if (this.ref.value == "")
            this.ref.value = activator;
        this.Append(cmp);
        return this;
    };
    return RadioSwitchesBuilder;
}(RefComponentBase_1.RefComponentBase));
exports.RadioSwitchesBuilder = RadioSwitchesBuilder;
