import { Button, Div, HashRef, ModalWindow, MultilineInput, NumberInput, Root, Select, Span, TextInput } from "@tblabs/truffle";
import { DestroyingPatternContentSwitcher } from "@tblabs/truffle";
import { TilesList } from "./TilesList";
import { TilesStorage } from "./TilesStorage";
import { TileView } from "./TileView";
import { TileEdit } from "./TileEdit";
import { AuthService } from "./AuthService";
import { LoginWindow } from "./LoginWindow";
import { User } from "./User";
import { MessageBus } from "./Services/MessageBus/MessageBus";
import { UserBox } from "./UserBox";


export class LoginPage extends Div
{
    constructor(_auth: AuthService)
    {
        super();

        new LoginWindow(_auth); 
    }
}

(async () =>
{
    NumberInput.DefaultCssClasses = "uk-input uk-form-width-small";
    TextInput.DefaultCssClasses = "uk-input";
    Button.DefaultCssClasses = "uk-button uk-button-default";
    Select.DefaultCssClasses = "uk-select";
    ModalWindow.DefaultCssClasses = "tf-modal-window";
    MultilineInput.DefaultCssClasses = "uk-textarea";
    ModalWindow.Hook = "#root";

    const root = new Root().Id("root");

    const _user = new User().LoadFromSessionStorage();
    console.log('User:', _user);
    const _messageBus = new MessageBus(_user, process.env.STORAGE || "");

    const _auth = new AuthService(_user);
    const _storage = new TilesStorage(_messageBus);

    const hash = new HashRef();

    root.Append(
        // new TextInput(hash),
        new UserBox(_user).FloatRight(),
        new DestroyingPatternContentSwitcher(hash)//.Id("switcher")
            .AddContent("", () => new LoginPage(_auth) )
            .AddContent("/login", () => new LoginPage(_auth) )
            .AddContent("/list", () => new TilesList(_user, _storage))
            .AddContent("/view/:id", ({ id }) => new TileView(id, _storage))
            .AddContent("/edit/:id", ({ id }) => new TileEdit(id, _storage, _user))
            .AddDefaultContent(() => new Span("Page not found"))
    )
})()
