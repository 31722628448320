import axios from "axios";
import { AuthQuery } from "./Models/Auth/AuthQuery";
import { AuthResult } from "./Models/Auth/AuthResult";
import { User } from "./User";

export class AuthService
{
    public TilesListResourceId: string = ""; // TODO: to tu mocno nie pasuje

    constructor(private _user: User)
    { }

    public async TryLogin(password: string): Promise<AuthResult>
    {
        try
        {
            const response = await axios.post("http://authv2.specteam.pl", new AuthQuery("Tily", password));

            const result = response.data as AuthResult;
            
            if (response.status === 200)
            {
                this._user.Id = result.UserId;
                this._user.Privileges = result.Privileges;
                this._user.Roles = result.Roles;
                this._user.Icon.value = result.Icon;
                this._user.ListOfTilesResourceId = result.Resource;
                this._user.Save();

                return AuthResult.Success();
            }
        }
        catch (error)
        {
            return AuthResult.Failed(error.message);
        }
        
        return AuthResult.Failed("Could not login");
    }
}
