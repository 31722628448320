"use strict";
exports.__esModule = true;
exports.RefReflector = void 0;
var Ref_1 = require("../Ref");
var RefReflector = /** @class */ (function () {
    function RefReflector() {
    }
    RefReflector.FindAllRefs = function (record) {
        this.refs = [];
        this.FindAllRefsInObject(record);
        return this.refs;
    };
    RefReflector.FindAllRefsInObject = function (obj) {
        var _this = this;
        obj.Keys().forEach(function (k) {
            var v = obj[k];
            if (v instanceof Ref_1.Ref) {
                _this.refs.push(v);
            }
            else if (v instanceof Object) {
                _this.FindAllRefsInObject(v);
            }
        });
    };
    RefReflector.refs = [];
    return RefReflector;
}());
exports.RefReflector = RefReflector;
