import { IMessage } from "../../Models/StorageMessages/IMessage";

export class StorageUpdateQuery implements IMessage
{
    public __Name = "Update";

    public App = process.env.APP_NAME || "";
    public CreateIfNotExist = true;

    constructor(
        public Id = "",
        public Content = "") { }
}
