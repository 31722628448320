import { Collection, RefFlattener } from "@tblabs/truffle";
import { TileData } from "./Models/TileData";
import { MessageBus } from "./Services/MessageBus/MessageBus";
import { StorageReadQuery } from "./Services/MessageBus/ReadQuery";
import { MessagePackage } from "./Services/MessageBus/MessagePackage";
import { StorageUpdateQuery } from "./Services/MessageBus/StorageUpdateQuery";

export class SavingResult
{
    constructor(
    public IsSaved = false,
    public ProblemReason = "")
    { }
}

export class TilesStorage
{
    constructor(private _messageBus: MessageBus)
    { }

    public async Save(tile: TileData, tileContent: string): Promise<SavingResult>
    {
        await this.SaveList();
        const updateContentCommand = new StorageUpdateQuery(tile.Id, tileContent);
        // console.log(updateContentCommand);
        const contentUpdateResult = await this._messageBus.Send(updateContentCommand);
        // console.log(contentUpdateResult);
        return new SavingResult(contentUpdateResult.IsSuccess, contentUpdateResult.ErrorMessage); 
    }

    private async SaveList()
    {
        console.log('saving list..');
        const arr = this.collection.Items.map(x => RefFlattener.Flat(x));
        const tilesList = JSON.stringify(arr);
        // console.log(tilesList);
        const listUpdateCommand = new StorageUpdateQuery(this.listId, tilesList);
        // console.log(listUpdateCommand);
        const listUpdateCommandResult = await this._messageBus.Send(listUpdateCommand);
        // console.log(listUpdateCommandResult);

        return listUpdateCommandResult;
    }

    public async GetContent(id: string): Promise<string>
    {
        const readQuery = new StorageReadQuery(id);

        const response = await this._messageBus.Send(readQuery);

        // if (response.IsSuccess)
        // {
        return response.Result || "(brak kontentu)";
        // }
    }

    public async Get(id: string): Promise<TileData | undefined>
    {
        if (!this.listDownloaded)
        {
            await this.GetList(sessionStorage.getItem('tiles-list-resource-id') || ""); // TODO: to powinno iść z _user
        }

        return this.collection.Items.find(x => x.Id === id)
    }

    private collection = new Collection<TileData>();
    private listDownloaded = false;
    private listId = "";

    public async GetList(listId: string): Promise<Collection<TileData>>
    {
        if (this.listDownloaded)
        {
            return this.collection;
        }

        this.listId = listId;

        const readQuery = new StorageReadQuery(listId);

        const response = await this._messageBus.Send(readQuery);

        if (response.IsSuccess)
        {
            const tiles = (JSON.parse(response.Result)) as TileData[];

            this.collection.Load(            
                ...tiles.map(t=> new TileData(t.Id, t.Title, t.Summary, t.Color, t.TextColor, t.Order || 100, t.Format || "txt")))

            this.listDownloaded = true;

            return this.collection;
        }
        else  
        {
            throw new Error("Could not load list");
        }
    }

    public async Add(tile: TileData): Promise<void>
    {
        this.collection.Add(tile);
        this.SaveList();
    }
}
