"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Pie = void 0;
var RefComponentBase_1 = require("../../Core/RefComponentBase");
// https://www.freecodecamp.org/news/css-only-pie-chart/
var Pie = /** @class */ (function (_super) {
    __extends(Pie, _super);
    function Pie(value) {
        var _this = _super.call(this, "div", value) || this;
        _this.Refresh();
        return _this;
        // this.Style(`--p: ${value}; --b: 22px; --c: #1e87f0;`)
        // this.Text(value.toString() + "%")
    }
    Pie.prototype.Refresh = function () {
        this.Html("<div class=\"pie\" style=\"--p:".concat(this.ref.value, ";--b:18px;--c:lightblue;\">").concat(this.ref.value, "%</div>"));
    };
    Pie.Name = "Pie";
    return Pie;
}(RefComponentBase_1.RefComponentBase));
exports.Pie = Pie;
