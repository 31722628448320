"use strict";
exports.__esModule = true;
exports.Sorter = void 0;
var Ref_1 = require("../Ref");
var Sorter = /** @class */ (function () {
    function Sorter(collection) {
        this.collection = collection;
        this.sorts = [];
    }
    Object.defineProperty(Sorter.prototype, "Selected", {
        get: function () {
            return this.selected;
        },
        enumerable: false,
        configurable: true
    });
    Sorter.prototype.Add = function (label, fn) {
        var _this = this;
        this.sorts.push({ label: label, fn: fn });
        if (!this.selected) {
            this.selected = new Ref_1.Ref(label);
            this.selected.value = label;
            this.Selected.OnChange(function (_) {
                _this.collection.Call(function (items) { return _this.SortBySelectedFunction(items); });
            });
        }
        return this;
    };
    Sorter.prototype.Reset = function () {
        this.selected.value = this.sorts[0].label;
        return this;
    };
    Object.defineProperty(Sorter.prototype, "Labels", {
        get: function () {
            return this.sorts.map(function (x) { return x.label; });
        },
        enumerable: false,
        configurable: true
    });
    Sorter.prototype.SortBySelectedFunction = function (items) {
        var _this = this;
        var _a;
        var fn = (_a = this.sorts.find(function (x) { return x.label === _this.selected.value; })) === null || _a === void 0 ? void 0 : _a.fn;
        return items.sort(fn);
    };
    return Sorter;
}());
exports.Sorter = Sorter;
