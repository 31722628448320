import { Div, Label, Link } from "@tblabs/truffle";
import { User } from "./User";

export class UserBox extends Div
{
    constructor(private _user: User)
    {
        super();
        this.PositionFixed().Top(8).Right(8)
        // this.Margin(16);

        this.Append(
            new Link("Logout").OnClick(() =>
            {
                _user.Reset();
                window.location.hash = "/login";
            }),
            new Label(_user.Icon).Border(1, "lightblue").BorderRadius(50).Padding(12).Background("lightblue").FontSize(18).Margin(8)
        );
    }
}
