import { Ref } from "@tblabs/truffle";

export class TileData
{
    public IsSelected = new Ref<boolean>(false);
    
    public Title = new Ref<string>("");
    public Summary = new Ref<string>("");
    public Color = new Ref<string>("");
    public TextColor = new Ref<string>("");
    public Order = new Ref<number>(0);
    public Format = new Ref<"txt" | "html" | "md">("txt");
    constructor(
        public Id = "",
        title,
        summary,
        color,
        textColor,
        order,
        format
    )
    {
        this.Title.value = title;
        this.Summary.value = summary;
        this.Color.value = color;
        this.TextColor.value = textColor;
        this.Order.value = order;
        this.Format.value = format;
    }
}
