"use strict";
exports.__esModule = true;
exports.RefFlattener = void 0;
var Ref_1 = require("../Ref");
var RefFlattener = /** @class */ (function () {
    function RefFlattener() {
    }
    RefFlattener.Flat = function (obj) {
        var _this = this;
        var flat = {};
        obj.Keys().forEach(function (k) {
            var v = obj[k];
            if (v.value instanceof Date) {
                flat[k] = v.value.toISOString();
            }
            else if (v instanceof Ref_1.Ref) {
                flat[k] = v.value;
            }
            else if (v instanceof Object) {
                flat[k] = _this.Flat(v);
            }
            else {
                flat[k] = v;
            }
        });
        return flat;
    };
    return RefFlattener;
}());
exports.RefFlattener = RefFlattener;
