export class AuthResult
{
    public static Failed(message: string): AuthResult
    {
        const result = new AuthResult(false);
        result.Message = message;

        return result;
    }
    
    public static Success(): AuthResult
    {
        const result = new AuthResult(true);
        result.Message = "";
        // result.Resource = resource;

        return result;
    }

    public UserId: string = "";
    public Privileges: string[] = [];
    public Roles: string[] = [];
    public Icon: string = "";
    public Resource: string = "";
    public Message: string = "";

    constructor(
        public LoginSuccess = false)
    { }
}
