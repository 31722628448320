import { Button, Div, Span } from "@tblabs/truffle";
import { Tile } from "./Tile";
import { TilesStorage } from "./TilesStorage";
import { AddTileWindow } from "./TileWindow";
import { User } from "./User";

export class TilesList extends Div
{
    private status = new Span("Loading...");

    constructor(private _user: User, private _storage: TilesStorage)
    {
        super();
    }

    protected async OnAppend()
    {
        this.Append(this.status)

        try
        {
            const resource = this._user.ListOfTilesResourceId || sessionStorage.getItem('tiles-list-resource-id')
            console.log("TilesList.OnAppend", resource);
            if (!resource)
            {
                this.status.Text("Could not load")
                return;
            }

            const tilesCollection = await this._storage.GetList(resource);
            sessionStorage.setItem('tiles-list-resource-id', resource)
            this.status.Text("")

            if (this._user.Can("CreateTile"))
                this.Append(
                    new Button("➕", () => new AddTileWindow(this._storage)).Margin(12));

            const tilesArea = new Div();

            tilesCollection.OnChange(tiles =>
            {
                tilesArea.Clear();
                tiles
                    .sort((a, b) => a.Order.value > b.Order.value ? 1 : -1)
                    .forEach(t => tilesArea.Append(new Tile(t, this._user, tilesCollection)))
            }, true)

            this.Append(
                tilesArea)


            if (this._user.Can("ListSelected"))
                this.Append(
                    new Button("List selected", () =>
                    {
                        alert(tilesCollection.Items.filter(x => x.IsSelected.value).map(x => `"${x.Id}"`).join(", "))
                    }))

        }
        catch (ex) 
        {
            this.status.Text("Loading error" + ex.message)
        }
    }
}
