"use strict";
exports.__esModule = true;
exports.HandlersCollection = void 0;
var HandlersCollection = /** @class */ (function () {
    function HandlersCollection() {
        this.handlers = [];
    }
    HandlersCollection.prototype.Reset = function () {
        this.handlers = [];
    };
    HandlersCollection.prototype.Add = function (handler) {
        if (handler)
            this.handlers.push(handler);
    };
    Object.defineProperty(HandlersCollection.prototype, "AreAny", {
        get: function () {
            return this.handlers.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    HandlersCollection.prototype.Call = function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        this.handlers.forEach(function (h) { return h.apply(void 0, params); });
    };
    HandlersCollection.prototype.CallAndGatherReturnValue = function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        var returnValues = [];
        this.handlers.forEach(function (h) {
            var result = h.apply(void 0, params);
            returnValues.push(result);
        });
        return returnValues;
    };
    return HandlersCollection;
}());
exports.HandlersCollection = HandlersCollection;
