"use strict";
exports.__esModule = true;
exports.ComponentBase = void 0;
var Ref_1 = require("./Ref");
require("./Extensions/ArrayExtensions");
var ChildrenCollection_1 = require("./ChildrenCollection");
var HandlersCollection_1 = require("./Tools/HandlersCollection");
var ComponentBase = /** @class */ (function () {
    function ComponentBase(baseElement) {
        this.baseElement = baseElement;
        this.id = -1;
        this.Name = "";
        this.IsRefComponent = false; // TODO: xxx
        this.Children = new ChildrenCollection_1.ChildrenCollection();
        this.onClickHandlers = new HandlersCollection_1.HandlersCollection();
        this.registeredEventListeners = [];
        this.isVisible = true;
        ComponentBase.Id += 1;
        this.id = ComponentBase.Id;
        this.element = ComponentBase.Create(baseElement);
    }
    ComponentBase.prototype.toString = function () {
        var _a;
        return "".concat(this.ID, ": parent=").concat((_a = this.Parent) === null || _a === void 0 ? void 0 : _a.ID, ", children=").concat(this.Children.toString());
    };
    Object.defineProperty(ComponentBase.prototype, "ID", {
        get: function () {
            return "[#".concat(this.id, ":").concat(this.baseElement).concat(this.Name ? ":" + this.Name : "").concat(this.IsRefComponent ? "(REF)" : "", "]");
        },
        enumerable: false,
        configurable: true
    });
    ComponentBase.prototype.LOG = function (str) {
        if (ComponentBase.LogEn) {
            console.log(str);
        }
    };
    ComponentBase.prototype.NameIt = function (name) {
        this.Name = name;
        return this;
    };
    //#endregion
    //#region CORE
    ComponentBase.prototype.Clear = function () {
        var _this = this;
        this.Children.Clone.forEach(function (c) {
            _this.Remove(c);
        });
        return this;
    };
    ComponentBase.prototype.Dispose = function () {
        // this.LOG(`💥 Disposing ${this.ID}...`);
    };
    ComponentBase.prototype.Remove = function (component) {
        var _a;
        if (!component) {
            throw new Error("Parameter is mandatory");
        }
        // this.LOG(`➖ Removing ${component.ID} from ${this.ID} (children: ${this.Children.map(x=>"#"+x.Id)})...`);
        this.LOG("\u2796 ".concat(this.ID, " -= ").concat(component.ID));
        if (!this.Children.Has(component)) {
            throw new Error("This is not your (".concat(this.ID, ") kid (").concat(component.ID, ")"));
            // return;
        }
        (_a = this.OnRemove) === null || _a === void 0 ? void 0 : _a.call(this);
        component.Children.Clone.forEach(function (c) {
            component.Remove(c);
        });
        if (this.Children.Remove(component)) {
            component.Dispose();
            this.element.Remove(component.element);
        }
        return this;
    };
    ComponentBase.prototype.AddComponent = function (component) {
        if (!component) {
            throw new Error("[TRUFFLE] Parameter/component to add is mandatory (Forget return 'this' from your component method?)");
        }
        if (component == this) {
            throw new Error("[TRUFFLE] You can't be child of yourself");
        }
        if (this.Children.Has(component)) // TODO: move to Add
         {
            throw new Error("[TRUFFLE] ".concat(component.ID, " was already added to ").concat(this.ID));
        }
        component.Parent = this;
        // this.LOG(`➕ ${this.ID} += ${component.ID}`);
        this.Children.Add(component);
    };
    ComponentBase.prototype.Prepend = function () {
        var _this = this;
        var components = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            components[_i] = arguments[_i];
        }
        components.forEach(function (component) {
            var _a;
            if (typeof (component) == "string") {
                var str = component;
                var splitted_1 = str.split("\n");
                if (splitted_1.length > 1) {
                    splitted_1.forEach(function (s, i) {
                        // We can't use Span (<span>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                        var span = new ComponentBase("span");
                        span.Text(s);
                        _this.AddComponent(span);
                        _this.element.Prepend(span.Element);
                        // Put NewLine between strings except last one
                        if (i != splitted_1.length - 1) {
                            // We can't use NewLine (<br>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                            var br = new ComponentBase("br");
                            _this.AddComponent(br);
                            _this.element.Prepend(br.Element);
                        }
                    });
                }
                else {
                    // We can't use Span (<span>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                    var span = new ComponentBase("span");
                    span.Text(str);
                    _this.AddComponent(span);
                    _this.element.Prepend(span.Element);
                }
            }
            else {
                _this.AddComponent(component); // AddChild?
                _this.element.Prepend(component.Element);
                (_a = component.OnAppend) === null || _a === void 0 ? void 0 : _a.call(component);
            }
        });
        return this;
    };
    ComponentBase.prototype.Append = function () {
        var _this = this;
        var components = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            components[_i] = arguments[_i];
        }
        components.forEach(function (component) {
            var _a;
            if (typeof (component) == "string") {
                var str = component;
                var splitted_2 = str.split("\n");
                if (splitted_2.length > 1) {
                    splitted_2.forEach(function (s, i) {
                        // We can't use Span (<span>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                        var span = new ComponentBase("span");
                        span.Text(s);
                        _this.AddComponent(span);
                        _this.element.Append(span.Element);
                        // Put NewLine between strings except last one
                        if (i != splitted_2.length - 1) {
                            // We can't use NewLine (<br>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                            var br = new ComponentBase("br");
                            _this.AddComponent(br);
                            _this.element.Append(br.Element);
                        }
                    });
                }
                else {
                    // We can't use Span (<span>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                    var span = new ComponentBase("span");
                    span.Text(str);
                    _this.AddComponent(span);
                    _this.element.Append(span.Element);
                }
            }
            else {
                _this.AddComponent(component);
                _this.element.Append(component.Element);
                (_a = component.OnAppend) === null || _a === void 0 ? void 0 : _a.call(component);
            }
        });
        return this;
    };
    ComponentBase.prototype.RemoveSelf = function () {
        var _a;
        // this.element.parentNode.Remove(this.element);
        (_a = this.Parent) === null || _a === void 0 ? void 0 : _a.Remove(this);
        return this;
    };
    Object.defineProperty(ComponentBase.prototype, "Element", {
        get: function () {
            return this.element;
        },
        enumerable: false,
        configurable: true
    });
    ComponentBase.Create = function (htmlElement) {
        return document.createElement(htmlElement);
    };
    //#endregion CORE
    //#region STYLING-ATTRIBUTES
    ComponentBase.prototype.Attribute = function (key, value) {
        this.element.setAttribute(key, value || null);
        return this;
    };
    ComponentBase.prototype.GetAttribute = function (key) {
        return this.element.getAttribute(key);
    };
    ComponentBase.prototype.RemoveAttribute = function (key) {
        this.element.removeAttribute(key);
        return this;
    };
    ComponentBase.prototype.TestAttr = function (id) {
        return this.Attribute('data-test', id);
    };
    ComponentBase.prototype.SetStyle = function (prop, val, valPostfix) {
        var _this = this;
        var Set = function (p, v, f) {
            if (f)
                _this.SetSingleStyle(p, v.toString() + f);
            else
                _this.SetSingleStyle(p, v);
        };
        if (val instanceof Ref_1.Ref) {
            Set(prop, val.value, valPostfix);
            val.OnChange(function (v) { return Set(prop, v, valPostfix); });
        }
        else {
            Set(prop, val, valPostfix);
        }
        return this;
    };
    //#endregion
    //#region STYLING-CLASS
    ComponentBase.prototype.Class = function (cssClasses) {
        var _this = this;
        if (!cssClasses)
            return this;
        cssClasses.split(" ").forEach(function (c) {
            return _this.element.classList.add(c);
        });
        return this;
    };
    ComponentBase.prototype.RemoveClass = function (klass) {
        if (klass)
            this.element.classList.remove(klass);
        return this;
    };
    // TODO: remove
    ComponentBase.prototype.AddClass = function (klass) {
        if (klass)
            this.element.classList.add(klass);
        return this;
    };
    ComponentBase.prototype.ToggleClasses = function (classA, classB) {
        if (this.element.classList.contains(classA)) {
            // console.log('contains A', classA, classB);
            this.element.classList.remove(classA);
            this.element.classList.add(classB);
        }
        else {
            // console.log('not contains A', classA, classB);
            this.element.classList.remove(classB);
            this.element.classList.add(classA);
        }
        return this;
    };
    //#endregion
    //#region STYLING-UTILS
    ComponentBase.prototype.Style = function (propertyOrCss, val) {
        var _this = this;
        if (propertyOrCss.includes(";")) {
            propertyOrCss.split(";").map(function (x) { return x.trim(); }).forEach(function (x) { return _this.SetSingleStyle(x); });
        }
        else
            this.SetSingleStyle(propertyOrCss, val);
        return this;
    };
    ComponentBase.prototype.SetSingleStyle = function (propertyOrCss, val) {
        if (val !== undefined) {
            this.element.style[propertyOrCss] = val;
        }
        else {
            var _a = propertyOrCss.split(":").map(function (x) { return x.trim(); }), key = _a[0], value = _a[1];
            this.element.style[key] = value;
        }
        return this;
    };
    ComponentBase.prototype.ApplyStyle = function (val, is, property, value, elseValue) {
        var _this = this;
        val.OnChange(function (v) {
            v == is ? _this.Style(property, value)
                : _this.Style(property, elseValue);
        });
        val.value == is ? this.Style(property, value)
            : this.Style(property, elseValue);
        return this;
    };
    ComponentBase.prototype.ToggleStyle = function (prop, val, elseVal) {
        if (getComputedStyle(this.element)[prop] !== val)
            this.element.style[prop] = val;
        else
            this.element.style[prop] = elseVal;
        return this;
    };
    //#endregion
    //#region STYLING-MISC
    ComponentBase.prototype.Cursor = function (cursor) {
        this.element.style.cursor = cursor;
        return this;
    };
    ComponentBase.prototype.CursorPointer = function () {
        return this.Cursor("pointer");
    };
    //#endregion
    //#region STYLING-VISUAL
    ComponentBase.prototype.ZIndex = function (index) {
        return this.SetStyle("zIndex", index);
    };
    ComponentBase.prototype.Opacity = function (opacity) {
        return this.SetStyle("opacity", opacity);
    };
    ComponentBase.prototype.Blur = function (value, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetSingleStyle("filter: blur(".concat(value).concat(unit, ")"));
    };
    //#endregion
    //#region STYLING-TEXT
    ComponentBase.prototype.Bold = function () {
        return this.SetSingleStyle("font-weight", "bold");
    };
    ComponentBase.prototype.FontBold = function () {
        return this.SetSingleStyle("font-weight", "bold");
    };
    ComponentBase.prototype.Normal = function () {
        return this.Style("font-weight: normal; font-style: normal");
    };
    ComponentBase.prototype.FontNormal = function () {
        return this.Style("font-weight: normal; font-style: normal");
    };
    ComponentBase.prototype.FontWeightNormal = function () {
        return this.Style("font-weight", "normal");
    };
    ComponentBase.prototype.FontWeightBold = function () {
        return this.Style("font-weight", "bold");
    };
    ComponentBase.prototype.Italic = function () {
        return this.SetSingleStyle("font-style", "italic");
    };
    ComponentBase.prototype.FontItalic = function () {
        return this.SetSingleStyle("font-style", "italic");
    };
    ComponentBase.prototype.FontSize = function (size, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("font-size", size, unit);
    };
    ComponentBase.prototype.TextAlign = function (align) {
        return this.SetStyle('text-align', align);
    };
    ComponentBase.prototype.TextAlignLeft = function () {
        this.element.style.textAlign = "left";
        return this;
    };
    ComponentBase.prototype.TextAlignRight = function () {
        this.element.style.textAlign = "right";
        return this;
    };
    ComponentBase.prototype.TextAlignCenter = function () {
        this.element.style.textAlign = "center";
        return this;
    };
    //#endregion STYLING-TEXT
    //#region STYLING-POSITION
    ComponentBase.prototype.Position = function (position) {
        this.element.style.position = position;
        return this;
    };
    ComponentBase.prototype.PositionAbsolute = function () {
        this.element.style.position = "absolute";
        return this;
    };
    ComponentBase.prototype.PositionRelative = function () {
        this.element.style.position = "relative";
        return this;
    };
    ComponentBase.prototype.PositionFixed = function () {
        this.element.style.position = "fixed";
        return this;
    };
    ComponentBase.prototype.Left = function (left, unit) {
        if (left === void 0) { left = 0; }
        if (unit === void 0) { unit = "px"; }
        this.element.style.left = left + unit;
        return this;
    };
    ComponentBase.prototype.LeftPercent = function (left) {
        if (left === void 0) { left = 0; }
        return this.Left(left, "%");
    };
    ComponentBase.prototype.Right = function (right, unit) {
        if (right === void 0) { right = 0; }
        if (unit === void 0) { unit = "px"; }
        this.element.style.right = right + unit;
        return this;
    };
    ComponentBase.prototype.RightPercent = function (right) {
        if (right === void 0) { right = 0; }
        return this.Right(right, "%");
    };
    ComponentBase.prototype.Top = function (top, unit) {
        if (top === void 0) { top = 0; }
        if (unit === void 0) { unit = "px"; }
        this.element.style.top = top + unit;
        return this;
    };
    ComponentBase.prototype.TopPercent = function (top) {
        if (top === void 0) { top = 0; }
        return this.Top(top, "%");
    };
    ComponentBase.prototype.Bottom = function (bottom, unit) {
        if (bottom === void 0) { bottom = 0; }
        if (unit === void 0) { unit = "px"; }
        this.element.style.bottom = bottom + unit;
        return this;
    };
    ComponentBase.prototype.BottomPercent = function (bottom) {
        if (bottom === void 0) { bottom = 0; }
        return this.Bottom(bottom, "%");
    };
    ComponentBase.prototype.FloatLeft = function () {
        this.element.style.float = "left";
        return this;
    };
    ComponentBase.prototype.FloatRight = function () {
        this.element.style.float = "right";
        return this;
    };
    ComponentBase.prototype.ClearBoth = function () {
        this.element.style.clear = "both";
        return this;
    };
    ComponentBase.prototype.ClearLeft = function () {
        this.element.style.clear = "left";
        return this;
    };
    ComponentBase.prototype.ClearRight = function () {
        this.element.style.clear = "right";
        return this;
    };
    //#endregion STYLING-POSITION
    //#region STYLING-DISPLAY
    ComponentBase.prototype.Display = function (as) {
        return this.SetSingleStyle("display: " + as);
    };
    ComponentBase.prototype.Inline = function () {
        return this.SetSingleStyle("display: inline");
    };
    ComponentBase.prototype.DisplayInline = function () {
        return this.SetSingleStyle("display: inline");
    };
    ComponentBase.prototype.Block = function () {
        return this.SetSingleStyle("display: block");
    };
    ComponentBase.prototype.DisplayBlock = function () {
        return this.SetSingleStyle("display: block");
    };
    ComponentBase.prototype.InlineBlock = function () {
        return this.SetSingleStyle("display: inline-block");
    };
    ComponentBase.prototype.DisplayInlineBlock = function () {
        return this.SetSingleStyle("display: inline-block");
    };
    //#endregion STYLING-DISPLAY
    //#region STYLING-BORDER
    ComponentBase.prototype.Border = function (width, color, radius) {
        if (radius === void 0) { radius = 0; }
        this.Style("border: ".concat(width, "px solid ").concat(color, "; border-radius: ").concat(radius, "px"));
        return this;
    };
    ComponentBase.prototype.BorderStyling = function (edge, width, color, radius, style) {
        if (radius === void 0) { radius = 0; }
        if (style === void 0) { style = "solid"; }
        this.Style("border-".concat(edge, ": ").concat(width, "px ").concat(style, " ").concat(color, "; border-").concat(edge, "-radius: ").concat(radius, "px"));
        return this;
    };
    ComponentBase.prototype.BorderBottom = function (width, color, style, radius) {
        if (width === void 0) { width = 1; }
        if (color === void 0) { color = "#888"; }
        if (style === void 0) { style = "solid"; }
        if (radius === void 0) { radius = 0; }
        return this.BorderStyling("bottom", width, color, radius, style);
    };
    ComponentBase.prototype.BorderTop = function (width, color, style, radius) {
        if (width === void 0) { width = 1; }
        if (color === void 0) { color = "#888"; }
        if (style === void 0) { style = "solid"; }
        if (radius === void 0) { radius = 0; }
        return this.BorderStyling("top", width, color, radius, style);
    };
    ComponentBase.prototype.BorderLeft = function (width, color, style, radius) {
        if (width === void 0) { width = 1; }
        if (color === void 0) { color = "#888"; }
        if (style === void 0) { style = "solid"; }
        if (radius === void 0) { radius = 0; }
        return this.BorderStyling("left", width, color, radius, style);
    };
    ComponentBase.prototype.BorderRight = function (width, color, style, radius) {
        if (width === void 0) { width = 1; }
        if (color === void 0) { color = "#888"; }
        if (style === void 0) { style = "solid"; }
        if (radius === void 0) { radius = 0; }
        return this.BorderStyling("right", width, color, radius, style);
    };
    ComponentBase.prototype.BorderStyle = function (style) {
        this.Style("border-style: ".concat(style));
        return this;
    };
    ComponentBase.prototype.BorderStyleSolid = function () {
        return this.BorderStyle("solid");
    };
    ComponentBase.prototype.BorderStyleDotted = function () {
        return this.BorderStyle("dotted");
    };
    ComponentBase.prototype.BorderStyleDashed = function () {
        return this.BorderStyle("dashed");
    };
    ComponentBase.prototype.BorderRadius = function (radius, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle('borderRadius', radius, unit);
    };
    //#endregion STYLING-BORDER
    //#region STYLING-SIZE
    ComponentBase.prototype.Width = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("width", val, unit);
    };
    ComponentBase.prototype.WidthPercent = function (val) {
        return this.SetStyle("width", val, "%");
    };
    ComponentBase.prototype.WidthAuto = function () {
        this.element.style.width = "auto";
        return this;
    };
    ComponentBase.prototype.HalfWidth = function () {
        this.element.style.width = "50%";
        return this;
    };
    ComponentBase.prototype.FullWidth = function () {
        this.element.style.width = "100%";
        return this;
    };
    ComponentBase.prototype.Height = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("height", val, unit);
    };
    ComponentBase.prototype.HeightPercent = function (val) {
        return this.SetStyle("height", val, "%");
    };
    ComponentBase.prototype.HeightAuto = function () {
        this.element.style.height = "auto";
        return this;
    };
    ComponentBase.prototype.MaxWidth = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.maxWidth = val + unit;
        return this;
    };
    ComponentBase.prototype.MinWidth = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.minWidth = val + unit;
        return this;
    };
    ComponentBase.prototype.MaxHeight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.maxHeight = val + unit;
        return this;
    };
    ComponentBase.prototype.MinHeight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.minHeight = val + unit;
        return this;
    };
    //#endregion STYLING-SIZE
    //#region STYLING-MARGIN
    ComponentBase.prototype.Margin = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("margin", val, unit);
    };
    ComponentBase.prototype.MarginAuto = function () {
        this.element.style.margin = "auto";
        return this;
    };
    ComponentBase.prototype.MarginTop = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("marginTop", val, unit);
    };
    ComponentBase.prototype.MarginBottom = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("marginBottom", val, unit);
    };
    ComponentBase.prototype.MarginTopBottom = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.marginTop = val + unit;
        this.element.style.marginBottom = val + unit;
        return this;
    };
    ComponentBase.prototype.MarginLeft = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("marginLeft", val, unit);
    };
    ComponentBase.prototype.MarginRight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("marginRight", val, unit);
    };
    ComponentBase.prototype.MarginLeftRight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.marginLeft = val + unit;
        this.element.style.marginRight = val + unit;
        return this;
    };
    //#endregion STYLING-MARGIN
    //#region STYLING-COLORS
    ComponentBase.prototype.Color = function (color) {
        return this.SetStyle("color", color);
    };
    ComponentBase.prototype.Background = function (color) {
        return this.SetStyle("background", color);
    };
    //#endregion STYLING-COLORS
    //#region STYLING-PADDING
    ComponentBase.prototype.Padding = function (val, secondVal) {
        if (!secondVal)
            this.SetStyle("padding", val, "px");
        else
            this.element.style.padding = "".concat(val, "px ").concat(secondVal, "px");
        return this;
    };
    ComponentBase.prototype.PaddingLeft = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("paddingLeft", val, unit);
    };
    ComponentBase.prototype.PaddingRight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("paddingRight", val, unit);
    };
    ComponentBase.prototype.PaddingTop = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("paddingTop", val, unit);
    };
    ComponentBase.prototype.PaddingBottom = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("paddingBottom", val, unit);
    };
    //#endregion STYLING-PADDING
    //#region STYLING-OVERFLOW
    ComponentBase.prototype.OverflowX = function (val) {
        this.element.style.overflowX = val;
        return this;
    };
    ComponentBase.prototype.OverflowY = function (val) {
        this.element.style.overflowY = val;
        return this;
    };
    ComponentBase.prototype.OverflowYScroll = function () {
        return this.OverflowY("scroll");
    };
    //#endregion STYLING-OVERFLOW
    //#region PROPS
    ComponentBase.prototype.Id = function (id) {
        return this.Attribute("id", id);
    };
    ComponentBase.prototype.Text = function (text) {
        this.element.innerText = text;
        return this;
    };
    ComponentBase.prototype.GetText = function () {
        return this.element.innerText;
    };
    ComponentBase.prototype.Html = function (html) {
        this.element.innerHTML = html;
        return this;
    };
    ComponentBase.prototype.GetHtml = function () {
        return this.element.innerHTML;
    };
    ComponentBase.prototype.Enable = function (isEnabled //, fn: (isEnabled: boolean) => boolean
    ) {
        var _this = this;
        if (typeof isEnabled === "boolean" && isEnabled === true) {
            this.RemoveAttribute("disabled");
        }
        else if (typeof isEnabled === "boolean" && isEnabled === false) {
            this.Attribute("disabled");
        }
        else {
            isEnabled.OnChange(function (v) {
                v ? _this.RemoveAttribute("disabled") : _this.Attribute("disabled");
            });
            isEnabled.value ? this.RemoveAttribute("disabled") : this.Attribute("disabled");
        }
        return this;
    };
    ComponentBase.prototype.Disable = function (isDisabled) {
        var _this = this;
        if (typeof isDisabled === "boolean" && isDisabled === true) {
            this.Attribute("disabled");
        }
        else if (typeof isDisabled === "boolean" && isDisabled === false) {
            this.RemoveAttribute("disabled");
        }
        else {
            isDisabled.OnChange(function (v) {
                v ? _this.Attribute("disabled") : _this.RemoveAttribute("disabled");
            });
            isDisabled.value ? this.Attribute("disabled") : this.RemoveAttribute("disabled");
        }
        return this;
    };
    ComponentBase.prototype.Enabled = function () {
        return this.Enable(true);
    };
    ComponentBase.prototype.Disabled = function () {
        return this.Enable(false);
    };
    //#endregion PROPS
    //#region EVENTS
    ComponentBase.prototype.AddEventHandler = function (event, handler) {
        this.element.addEventListener(event, handler);
        return this;
    };
    ComponentBase.prototype.OnWheel = function (callback) {
        this.AddEventHandler('wheel', function (e) {
            callback(e.wheelDelta > 0);
        });
        return this;
    };
    ComponentBase.prototype.OnClick = function (handler) {
        var _this = this;
        if (!this.onClickHandlers.AreAny) {
            this.element.addEventListener('click', function () { return _this.onClickHandlers.Call(_this); });
        }
        this.onClickHandlers.Add(handler);
        return this;
    };
    ComponentBase.prototype.Click = function () {
        this.onClickHandlers.Call();
        return this;
    };
    ComponentBase.prototype.RemoveOnClick = function () {
        if (this.onClickHandlers.AreAny) {
            this.element.removeEventListener('click', function () { });
        }
        this.onClickHandlers.Reset();
        return this;
    };
    ComponentBase.prototype.OnHover = function (callback) {
        var _this = this;
        this.element.addEventListener('mouseover', function () { return callback(_this); });
        return this;
    };
    ComponentBase.prototype.OnMouseOut = function (callback) {
        var _this = this;
        this.element.addEventListener('mouseout', function () { return callback(_this); });
        return this;
    };
    ComponentBase.prototype.OnRightClick = function (callback) {
        var _this = this;
        this.element.addEventListener('contextmenu', function (e) {
            e.preventDefault();
            callback(_this);
            return false;
        }, false);
        return this;
    };
    ComponentBase.prototype.OnFocus = function (callback) {
        var _this = this;
        this.element.addEventListener('focus', function () { return callback(_this); });
        return this;
    };
    ComponentBase.prototype.OnBlur = function (callback) {
        var _this = this;
        this.element.addEventListener('blur', function () { return callback(_this); });
        return this;
    };
    ComponentBase.prototype.On = function (event, handler) {
        if (this.registeredEventListeners.includes(event)) {
            console.warn("Event \"".concat(event, "\" is already registered for this component (").concat(this.toString(), ")"));
            return this;
        }
        this.registeredEventListeners.push(event);
        this.element.addEventListener(event, function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return handler.apply(void 0, args);
        });
        return this;
    };
    ComponentBase.prototype.OnAnyKeyUp = function (callback) {
        this.On('keyup', function (e) {
            callback(e.keyCode);
        });
        return this;
    };
    ComponentBase.prototype.OnAnyKeyDown = function (callback) {
        this.On('keydown', function (e) {
            callback(e.keyCode);
        });
        return this;
    };
    ComponentBase.prototype.OnEnterKeyPressed = function (callback) {
        this.On('keyup', function (e) {
            if (e.keyCode === 13) {
                callback();
            }
        });
        return this;
    };
    ComponentBase.prototype.OnKeyUp = function (keyCode, callback) {
        // console.log('reg on key', keyCode);
        this.On('keyup', function (e) {
            if (e.code === keyCode) {
                callback();
            }
        });
        return this;
    };
    //#endregion EVENTS
    ComponentBase.prototype.Exist = function (component) {
        return this.element.Exist(component.Element);
    };
    //#region VISIBILITY
    ComponentBase.prototype.Visible = function (show, as) {
        var _this = this;
        if (as === void 0) { as = "inline-block"; }
        if (show instanceof Ref_1.Ref) {
            show.OnChange(function (v) { return v ? _this.Show(as) : _this.Hide(); });
            show.value ? this.Show(as) : this.Hide();
        }
        else if (typeof show == "boolean") {
            show ? this.Show(as) : this.Hide();
        }
        return this;
    };
    ComponentBase.prototype.VisibleWhen = function (ref, predicate, as) {
        var _this = this;
        if (as === void 0) { as = "inline-block"; }
        ref.OnChange(function (v) { return predicate(v) ? _this.Show(as) : _this.Hide(); });
        predicate(ref.value) ? this.Show(as) : this.Hide();
        return this;
    };
    ComponentBase.prototype.Hide = function () {
        var _a;
        this.isVisible = false;
        (_a = this.OnHide) === null || _a === void 0 ? void 0 : _a.call(this);
        this.element.Hide();
        return this;
    };
    ComponentBase.prototype.IsVisible = function () {
        return this.isVisible;
        // return this.element.IsVisible();
    };
    ComponentBase.prototype.Show = function (as) {
        var _a;
        if (as === void 0) { as = "inline-block"; }
        this.showAs = as;
        this.isVisible = true;
        this.element.Show(as);
        (_a = this.OnShow) === null || _a === void 0 ? void 0 : _a.call(this);
        return this;
    };
    ComponentBase.prototype.Toggle = function () {
        // this.isVisible = !this.isVisible;
        this.isVisible ? this.Hide() : this.Show(this.showAs);
        // this.element.Toggle();
        return this;
    };
    //#endregion
    ComponentBase.prototype.Focus = function () {
        this.element.Focus();
        return this;
    };
    ComponentBase.prototype.ScrollToBottom = function () {
        this.element.scrollTop = this.element.scrollHeight;
        return this;
    };
    //#region LOGS
    ComponentBase.LogEn = false;
    ComponentBase.Id = 0;
    return ComponentBase;
}());
exports.ComponentBase = ComponentBase;
