"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.HidingContentSwitcher = void 0;
var RefComponentBase_1 = require("../../../Core/RefComponentBase");
var HidingContentSwitcher = /** @class */ (function (_super) {
    __extends(HidingContentSwitcher, _super);
    function HidingContentSwitcher(ref) {
        var _this = _super.call(this, "div", ref) || this;
        _this.contents = {};
        return _this;
    }
    HidingContentSwitcher.prototype.AddContent = function (activator, cmp) {
        this.contents[activator] = cmp();
        if (activator === this.ref.value) {
            this.Append(this.contents[activator]);
        }
        return this;
    };
    HidingContentSwitcher.prototype.AddDefaultContent = function (cmp) {
        this.defaultContent = cmp();
        if (!this.IsOneOfTheContents()) {
            this.Append(this.defaultContent);
        }
        return this;
    };
    HidingContentSwitcher.prototype.IsOneOfTheContents = function () {
        return this.contents.Keys().includes(this.ref.value);
    };
    HidingContentSwitcher.prototype.Refresh = function () {
        var _a, _b;
        this.contents.Values().forEach(function (x) { return x.Hide(); });
        var newContent = this.contents[this.ref.value];
        if (newContent) {
            (_a = this.defaultContent) === null || _a === void 0 ? void 0 : _a.Hide();
            if (!this.Exist(newContent)) {
                this.Append(newContent);
            }
            newContent.Show();
        }
        else {
            if (this.defaultContent && !this.Exist(this.defaultContent)) {
                this.Append(this.defaultContent);
            }
            (_b = this.defaultContent) === null || _b === void 0 ? void 0 : _b.Show();
        }
    };
    HidingContentSwitcher.Name = "HidingContentSwitcher";
    return HidingContentSwitcher;
}(RefComponentBase_1.RefComponentBase));
exports.HidingContentSwitcher = HidingContentSwitcher;
