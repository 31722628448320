import { Div, MultilineInput, TwinColumns } from "@tblabs/truffle";
import { Markdown } from "./Markdown";

export class MarkdownEdit extends TwinColumns
{
    constructor(tileContentRef)
    {
        super();

        const md = new Markdown(tileContentRef.value);
        tileContentRef.OnChange(v => md.Text(v));

        this.LeftColumn.Append(
            new MultilineInput(tileContentRef).Rows(20).Padding(12)
        );
        this.RightColumn.Background("lightblue").Color("#222").Padding(12)
        .Append(
            md
        );
    }
}

export class HtmlEdit extends TwinColumns
{
    constructor(tileContentRef)
    {
        super();

        const html = new Div();
        tileContentRef.OnChange(v => html.Html(v), true);

        this.LeftColumn.Append(
            new MultilineInput(tileContentRef).Rows(20)
        );
        this.RightColumn.Background("lightblue")
        .Append(
            html
        );
    }
}
