"use strict";
exports.__esModule = true;
exports.$ = void 0;
function $(query) {
    return document.querySelector(query);
}
exports.$ = $;
Object.prototype.Keys = function () {
    if (this === undefined)
        return [];
    return Object.keys(this);
};
Object.prototype.Values = function () {
    if (this === undefined)
        return [];
    return Object.values(this);
};
HTMLElement.prototype.Hide = function () {
    this.style.display = "none";
};
HTMLElement.prototype.Show = function (as) {
    if (as === void 0) { as = "inline-block"; }
    this.style.display = as;
};
HTMLElement.prototype.Visability = function (show, as) {
    if (as === void 0) { as = "inline-block"; }
    if (show)
        this.Show(as);
    else
        this.Hide();
};
HTMLElement.prototype.IsVisible = function () {
    // var element = document.getElementById("myElement");
    var bounding = this.getBoundingClientRect();
    return (bounding.top >= 0 && bounding.left >= 0 && bounding.bottom <= window.innerHeight && bounding.right <= window.innerWidth);
    // console.log('IsVisible', getComputedStyle(this).display);
    // if (!getComputedStyle(this).display) return false;
    // if (getComputedStyle(this).display == "") return false;
    // return getComputedStyle(this).display !== "none";
};
HTMLElement.prototype.Toggle = function () {
    this.IsVisible() ? this.Hide() : this.Show();
};
HTMLElement.prototype.Append = function (child) {
    this.appendChild(child);
    return this;
};
HTMLElement.prototype.Prepend = function (child) {
    this.prepend(child);
    return this;
};
// HTMLElement.prototype.Insert = function(child: HTMLElement) 
// {
//     this.innerHTML = child;
//     return this;
// }
HTMLElement.prototype.Exist = function (child) {
    var ch = Array.from(this.children);
    return !!ch.find(function (c) { return c === child; });
};
HTMLElement.prototype.Remove = function (child) {
    this.removeChild(child);
    return this;
};
HTMLElement.prototype.Clear = function () {
    this.innerHTML = "";
    return this;
};
HTMLInputElement.prototype.Enabled = function () {
    this.disabled = false;
    return this;
};
HTMLInputElement.prototype.Disabled = function () {
    this.disabled = true;
    return this;
};
HTMLButtonElement.prototype.Enabled = function () {
    this.disabled = false;
    return this;
};
HTMLButtonElement.prototype.Disabled = function () {
    this.disabled = true;
    return this;
};
HTMLElement.prototype.Text = function (text) {
    this.innerText = text;
    return this;
};
HTMLInputElement.prototype.Value = function (text) {
    this.value = text;
};
HTMLInputElement.prototype.Focus = function () {
    this.focus();
    // this.selectionStart = this.selectionEnd = this.value.length;
    return this;
};
