"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Rate = exports.Star = void 0;
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var Div_1 = require("../Containers/Div");
var Star = /** @class */ (function (_super) {
    __extends(Star, _super);
    function Star(rating, showWhenLowerOrEqual) {
        var _this = _super.call(this, "span", rating) || this;
        _this.showWhenLowerOrEqual = showWhenLowerOrEqual;
        _this.Style("cursor", "pointer");
        _this.Style("opacity", "0.6");
        _this.Refresh();
        return _this;
    }
    Star.prototype.Refresh = function () {
        this.Text(this.ref.value >= this.showWhenLowerOrEqual ? "◼" : "◻");
    };
    return Star;
}(RefComponentBase_1.RefComponentBase));
exports.Star = Star;
var Rate = /** @class */ (function (_super) {
    __extends(Rate, _super);
    function Rate(rating, count) {
        if (count === void 0) { count = 5; }
        var _this = _super.call(this) || this;
        var _loop_1 = function (i) {
            this_1.Append(new Star(rating, i)
                .OnClick(function () { return rating.value = i; }));
        };
        var this_1 = this;
        for (var i = 1; i <= count; i++) {
            _loop_1(i);
        }
        return _this;
    }
    Rate.Name = "Rate";
    return Rate;
}(Div_1.Div));
exports.Rate = Rate;
