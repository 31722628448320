import { Div } from "@tblabs/truffle";
import showdown from "showdown";

export class Markdown extends Div
{
    public static Name = "Markdown";

    private options = { tables: true, tasklists: true, underline: true, strikethrough: true, backslashEscapesHTMLTags: true };
    private converter = new showdown.Converter(this.options);

    constructor(markdown?: string)
    {
        super();
        this.Style("padding: 0; margin: 0;");

        this.Text(markdown);
    }

    public Text(markdown?: string): this
    {
        if (!markdown)
            return this;

        const html = this.converter.makeHtml(markdown);
        this.element.innerHTML = html;

        return this;
    }
}
