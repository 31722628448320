"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.CollectionView = void 0;
var Ref_1 = require("./Ref");
var RefBase_1 = require("./RefBase");
var CollectionView = /** @class */ (function (_super) {
    __extends(CollectionView, _super);
    function CollectionView(collection) {
        var _this = _super.call(this) || this;
        _this.collection = collection;
        _this.Items = [];
        _this.filters = [];
        _this.FilteredCount = new Ref_1.Ref(0);
        collection.AddSubscriber(_this);
        return _this;
    }
    Object.defineProperty(CollectionView.prototype, "Source", {
        get: function () {
            return this.collection;
        },
        enumerable: false,
        configurable: true
    });
    CollectionView.prototype.OnChange = function (handler) {
        this.emitter.AddHandler(handler);
        return this;
    };
    CollectionView.prototype.ForEach = function (fn) {
        this.onRefresh = fn;
        return this;
    };
    CollectionView.prototype.Refresh = function () {
        var _this = this;
        this.Items = this.collection.Items;
        this.filters.forEach(function (f) {
            _this.Items = _this.Items.filter(function (x) { return f(x); });
        });
        this.FilteredCount.value = this.Items.length;
        if (this.start && this.count) {
            this.Items = this.Items.slice(this.start.value, this.start.value + this.count.value);
        }
        if (this.onRefresh)
            this.Items.forEach(function (record) { return _this.onRefresh(record); });
        this.emitter.CallHandlers(this.Items);
        this.RefreshSubscribers();
        return this;
    };
    CollectionView.prototype.Filter = function (ref, fn) {
        ref.AddSubscriber(this);
        this.filters.push(fn);
        return this;
    };
    CollectionView.prototype.See = function (start, count) {
        start.AddSubscriber(this);
        count.AddSubscriber(this);
        this.start = start;
        this.count = count;
        return this;
    };
    return CollectionView;
}(RefBase_1.RefBase));
exports.CollectionView = CollectionView;
