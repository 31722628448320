import { Button, ModalWindow, MultilineInput, TextInput } from "@tblabs/truffle";
import { TileData } from "./Models/TileData";
import { v4 as uuidv4 } from 'uuid';
import { TilesStorage } from "./TilesStorage";

export class AddTileWindow extends ModalWindow
{
    constructor(private _storage: TilesStorage)
    {
        super("Tile edit");

        const tile = new TileData(uuidv4(), "", "", "#aaa", "#888", 100, "txt");

        this.AddContent(
            new TextInput(tile.Title).Placeholder("Title"),
            new TextInput(tile.Summary).Placeholder("Summary"),
            // new MultilineInput(tile.Content).Placeholder("Content")
        );

        this.AddToFooter(
            new Button("Add", async () =>
            {
                await this._storage.Add(tile);
                this.CloseWindow();
            })
        );

        this.OpenWindow();
    }
}
