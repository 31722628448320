"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Select = void 0;
var __1 = require("../..");
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var Select = /** @class */ (function (_super) {
    __extends(Select, _super);
    function Select(ref, initialOptions) {
        if (ref === void 0) { ref = new __1.Ref(""); }
        if (initialOptions === void 0) { initialOptions = []; }
        var _this = _super.call(this, "select", ref) || this;
        _this.initialOptions = initialOptions;
        _this.options = [];
        _this.handler = function () {
            // console.log('[RemoveDefaultOptionOnFirstClick]', this.ref.value);
            _this.RemoveDefaultOption();
            _this.element.removeEventListener('click', _this.handler);
            // console.log('VVVVVVVVV222', this.element.value);
            _this.ref.value = _this.element.value; // BEZ TEGO PO KLIKNIĘCU KONTROLKA POKAZUJE PIERWSZĄ WARTOŚĆ ALE ref SIĘ NIE USTAWIA NA TE WARTOŚĆ
        };
        _this.modifyValueAfterChangeFn = function (t) { return t; };
        _this.DefaultValue = "__default__";
        _this.wasDefaultValueEverSet = false;
        _this.removeItOnFirstClick = false;
        _this.AddClass(Select.DefaultCssClasses);
        initialOptions.forEach(function (o) { return _this.AddOption(o); });
        _this.element.addEventListener('change', function (e) {
            var _a, _b;
            // console.log('[change!]');
            _this.ref.value = _this.modifyValueAfterChangeFn(e.target.value);
            var index = _this.options.findIndex(function (x) { return x.Value === e.target.value; });
            var key = ((_a = _this.options.find(function (x) { return x.Value === e.target.value; })) === null || _a === void 0 ? void 0 : _a.Text) || "";
            (_b = _this.onChangeCallback) === null || _b === void 0 ? void 0 : _b.call(_this, _this.ref.value, index, key);
        });
        _this.element.value = ref.value.toString();
        // console.log('VVVVVVVVV111', this.element.value);
        if (_this.removeItOnFirstClick) {
            _this.RemoveDefaultOptionOnFirstClick();
        }
        return _this;
    }
    Object.defineProperty(Select.prototype, "Index", {
        get: function () {
            var _this = this;
            return this.options.findIndex(function (x) { return x.Value === _this.ref.value; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Select.prototype, "Key", {
        get: function () {
            var _this = this;
            var _a;
            return ((_a = this.options.find(function (x) { return x.Value === _this.ref.value; })) === null || _a === void 0 ? void 0 : _a.Text)
                || this.defaultOptionText;
        },
        enumerable: false,
        configurable: true
    });
    Select.prototype.RemoveDefaultOptionOnFirstClick = function () {
        /*
            UŻYCIE TEJ OPCJI SPOWODUJE USUNIĘCIE WARTOŚCI DOMYŚLNEJ Z LISTY OPCJI
            ALE RÓWNIEŻ USTAWI PIERWSZĄ OPCJĘ. DEFAULTOWA JUŻ SIĘ WIĘCEJ NIE POJAWI.
            NIE WIEM CZY NIE LEPIEJ BYŁO BY Z TEGO ZREZYGNOWAĆ.
        */
        this.element.addEventListener('click', this.handler);
        // this.OnClick(this.handler)
        return this;
    };
    Object.defineProperty(Select.prototype, "IsOpen", {
        get: function () {
            return document.activeElement === this.element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Select.prototype, "Options", {
        get: function () {
            return this.options;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Select.prototype, "InitialOptions", {
        get: function () {
            return this.initialOptions;
        },
        enumerable: false,
        configurable: true
    });
    Select.prototype.ModifyAfterChange = function (fn) {
        this.modifyValueAfterChangeFn = fn;
        return this;
    };
    Select.prototype.CreateOption = function (option) {
        var _a;
        var optionElement = document.createElement("option");
        optionElement.text = option.Text;
        optionElement.value = ((_a = option.Value) === null || _a === void 0 ? void 0 : _a.toString()) || "";
        return optionElement;
    };
    Select.prototype.PrependOption = function (option) {
        this.options.unshift(option);
        var optionElement = this.CreateOption(option);
        this.element.Prepend(optionElement);
        this.Refresh();
        return this;
    };
    Select.prototype.AddOption = function (option) {
        this.options.push(option);
        var optionElement = this.CreateOption(option);
        this.element.Append(optionElement);
        this.Refresh();
        return this;
    };
    Object.defineProperty(Select.prototype, "IsDefault", {
        get: function () {
            return !this.IsValueInOptions();
        },
        enumerable: false,
        configurable: true
    });
    Select.prototype.AddDefaultOption = function (text, removeItOnFirstClick) {
        if (removeItOnFirstClick === void 0) { removeItOnFirstClick = false; }
        this.removeItOnFirstClick = removeItOnFirstClick;
        this.wasDefaultValueEverSet = true;
        this.defaultOptionText = text;
        this.defaultOptionElement = document.createElement("option");
        this.defaultOptionElement.text = text;
        this.defaultOptionElement.value = this.DefaultValue;
        this.element.Append(this.defaultOptionElement);
        this.Refresh();
        return this;
    };
    Select.prototype.RemoveDefaultOption = function () {
        if (this.defaultOptionElement) {
            // console.log('removing', this.ref.value);
            this.element.removeChild(this.defaultOptionElement);
            this.defaultOptionElement = null;
        }
        return this;
    };
    Select.prototype.ClearOptions = function () {
        this.options = [];
        this.element.textContent = '';
        if (this.wasDefaultValueEverSet) {
            this.AddDefaultOption(this.defaultOptionText);
        }
        return this;
    };
    Select.prototype.AddOptions = function (options) {
        var _this = this;
        options.forEach(function (o) { return _this.AddOption(o); });
        return this;
    };
    Select.prototype.LoadOptions = function (options) {
        var _this = this;
        this.ClearOptions();
        options.forEach(function (o) { return _this.AddOption(o); });
        this.Refresh();
        return this;
    };
    Select.prototype.LoadInitialOptions = function () {
        return this.LoadOptions(this.initialOptions);
    };
    Select.prototype.OnChange = function (callback) {
        this.onChangeCallback = callback;
        return this;
    };
    Select.prototype.Refresh = function () {
        // console.log('[Refresh]', this.Name ? this.Name : "no-name");
        if (this.IsValueInOptions()) {
            // console.log('[refr] val=ref');
            this.element.value = this.ref.value.toString();
        }
        else {
            // console.log('[refr] val=def');
            this.element.value = this.DefaultValue;
        }
        if (this.showThatChanged) {
            this.ref.HasChanged
                ? this.element.classList.add("tf-value-changed")
                : this.element.classList.remove("tf-value-changed");
        }
        return this;
    };
    Select.prototype.IsValueInOptions = function () {
        var refValue = this.ref.value.toString();
        return this.options.map(function (x) { var _a; return (_a = x.Value) === null || _a === void 0 ? void 0 : _a.toString(); }).includes(refValue);
    };
    Select.Name = "Select";
    Select.DefaultCssClasses = "";
    return Select;
}(RefComponentBase_1.RefComponentBase));
exports.Select = Select;
