"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.DestroyingPatternContentSwitcherV0 = exports.DestroyingPatternContentSwitcher = void 0;
var RefComponentBase_1 = require("../../../Core/RefComponentBase");
var PatternExtractor_1 = require("../../../Core/Tools/PatternExtractor");
var DestroyingPatternContentSwitcher = /** @class */ (function (_super) {
    __extends(DestroyingPatternContentSwitcher, _super);
    function DestroyingPatternContentSwitcher(ref) {
        var _this = _super.call(this, "div", ref) || this;
        _this.contents = {}; // TODO: to jest słabe, trzeba by to wynieść do jakiejś osobnej klasy
        return _this;
    }
    DestroyingPatternContentSwitcher.prototype.AddContent = function (pattern, cmp) {
        this.contents[pattern] = cmp;
        var extractor = new PatternExtractor_1.PatternExtractor(pattern);
        var params = extractor.Extract(this.ref.value);
        if (params == null) // a co jak mamy pattern bez parametrów? zwraca pusty obiekt? TAK!
         {
            return this;
        }
        this.Append(cmp(params));
        return this;
    };
    DestroyingPatternContentSwitcher.prototype.AddDefaultContent = function (defaultCmp) {
        this.defaultContent = defaultCmp;
        if (!this.IsRefOneOfPatterns()) {
            this.Append(defaultCmp());
        }
        return this;
    };
    DestroyingPatternContentSwitcher.prototype.IsRefOneOfPatterns = function () {
        for (var _i = 0, _a = this.contents.Keys(); _i < _a.length; _i++) {
            var pattern = _a[_i];
            var extractor = new PatternExtractor_1.PatternExtractor(pattern);
            var params = extractor.Extract(this.ref.value);
            if (params != null)
                return true;
        }
        return false;
    };
    DestroyingPatternContentSwitcher.prototype.FindPatternAndParams = function () {
        for (var _i = 0, _a = this.contents.Keys(); _i < _a.length; _i++) {
            var pattern = _a[_i];
            var extractor = new PatternExtractor_1.PatternExtractor(pattern);
            var params = extractor.Extract(this.ref.value);
            if (params != null)
                return ({ found: true, pattern: pattern, params: params });
        }
        return ({ found: false, pattern: null, params: null });
    };
    DestroyingPatternContentSwitcher.prototype.Refresh = function () {
        this.Clear();
        var searchResult = this.FindPatternAndParams();
        if (searchResult.found) {
            this.Append(this.contents[searchResult.pattern](searchResult.params));
        }
        else {
            if (this.defaultContent)
                this.Append(this.defaultContent());
        }
    };
    DestroyingPatternContentSwitcher.Name = "DestroyingPatternContentSwitcher";
    return DestroyingPatternContentSwitcher;
}(RefComponentBase_1.RefComponentBase));
exports.DestroyingPatternContentSwitcher = DestroyingPatternContentSwitcher;
var DestroyingPatternContentSwitcherV0 = /** @class */ (function (_super) {
    __extends(DestroyingPatternContentSwitcherV0, _super);
    function DestroyingPatternContentSwitcherV0(ref) {
        var _this = _super.call(this, "div", ref) || this;
        _this.contents = {};
        return _this;
    }
    DestroyingPatternContentSwitcherV0.prototype.AddContent = function (pattern, cmp) {
        this.contents[pattern] = cmp;
        var extractor = new PatternExtractor_1.PatternExtractor(pattern);
        var result = extractor.Extract(this.ref.value);
        if (result == null) {
            if (this.defaultContent)
                this.Append(this.defaultContent());
            return this;
        }
        this.Append(cmp(result));
        return this;
    };
    DestroyingPatternContentSwitcherV0.prototype.AddDefaultContent = function (cmp) {
        this.defaultContent = cmp;
        if (!this.Find(this.ref.value)) {
            this.Append(cmp());
        }
        return this;
    };
    DestroyingPatternContentSwitcherV0.prototype.Find = function (input) {
        for (var _i = 0, _a = this.contents.Keys(); _i < _a.length; _i++) {
            var pattern = _a[_i];
            var extractor = new PatternExtractor_1.PatternExtractor(pattern);
            var params = extractor.Extract(input);
            if (params != null)
                return this.contents[pattern](params);
        }
        return null;
    };
    DestroyingPatternContentSwitcherV0.prototype.Refresh = function () {
        var component = this.Find(this.ref.value);
        this.Clear();
        if (component) {
            this.Append(component);
        }
        else {
            if (this.defaultContent)
                this.Append(this.defaultContent());
        }
    };
    DestroyingPatternContentSwitcherV0.Name = "DestroyingPatternContentSwitcher";
    return DestroyingPatternContentSwitcherV0;
}(RefComponentBase_1.RefComponentBase));
exports.DestroyingPatternContentSwitcherV0 = DestroyingPatternContentSwitcherV0;
