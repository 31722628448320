"use strict";
exports.__esModule = true;
exports.PatternExtractor = void 0;
var PatternExtractor = /** @class */ (function () {
    function PatternExtractor(pattern) {
        this.pattern = pattern;
    }
    PatternExtractor.prototype.Extract = function (url) {
        var urlParts = url.split('/');
        var patternParts = this.pattern.split('/');
        var params = {};
        if (urlParts.length !== patternParts.length) {
            return null;
        }
        for (var i = 0; i < urlParts.length; i++) {
            if (patternParts[i].startsWith(':')) {
                var paramName = patternParts[i].substring(1);
                params[paramName] = urlParts[i];
            }
            else if (urlParts[i] !== patternParts[i]) {
                return null;
            }
        }
        return params;
    };
    return PatternExtractor;
}());
exports.PatternExtractor = PatternExtractor;
