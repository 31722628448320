"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.NonRefCheckboxV2 = exports.NonRefCheckbox = exports.Checkbox = void 0;
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var GenericComponent_1 = require("../GenericComponent");
var Ref_1 = require("../../Core/Ref");
var ComponentBase_1 = require("../../Core/ComponentBase");
var TextLabel_1 = require("./TextLabel");
var Checkbox = /** @class */ (function (_super) {
    __extends(Checkbox, _super);
    function Checkbox(ref, label) {
        if (ref === void 0) { ref = new Ref_1.Ref(false); }
        var _this = _super.call(this, "label", ref) || this;
        _this.ref = ref;
        _this.input = new GenericComponent_1.GenericComponent("input")
            .AddClass("uk-checkbox")
            .Attribute("type", "checkbox");
        if (ref.value)
            _this.input.Attribute("checked", ref.value ? "checked" : "");
        _this.element.addEventListener('click', function (e) {
            _this.ref.value = _this.input.element.checked;
        });
        _this.Append(_this.input);
        if (label)
            _this.Append(new TextLabel_1.TextLabel(" " + label));
        return _this;
    }
    Checkbox.prototype.Refresh = function () {
        this.input.element.checked = this.ref.value;
        if (this.showThatChanged) {
            this.ref.HasChanged
                ? this.input.element.classList.add("tf-value-changed")
                : this.input.element.classList.remove("tf-value-changed");
        }
    };
    Checkbox.prototype.Disabled = function () {
        this.input.Attribute("disabled", "disabled");
        return this;
    };
    Checkbox.Name = "Checkbox";
    return Checkbox;
}(RefComponentBase_1.RefComponentBase));
exports.Checkbox = Checkbox;
var NonRefCheckbox = /** @class */ (function (_super) {
    __extends(NonRefCheckbox, _super);
    function NonRefCheckbox(value, onChange, label) {
        var _this = _super.call(this, "label") || this;
        _this.value = value;
        _this.input = new GenericComponent_1.GenericComponent("input")
            .AddClass("uk-checkbox")
            .Attribute("type", "checkbox");
        if (value())
            _this.input.Attribute("checked");
        else
            _this.input.RemoveAttribute("checked");
        _this.element.addEventListener('click', function (e) {
            console.log('!', _this.input.element.checked);
            onChange(_this.input.element.checked);
        });
        _this.Append(_this.input);
        if (label)
            _this.Append(new TextLabel_1.TextLabel(" " + label));
        return _this;
    }
    NonRefCheckbox.prototype.Refresh = function () {
        this.input.element.checked = this.value();
    };
    return NonRefCheckbox;
}(ComponentBase_1.ComponentBase));
exports.NonRefCheckbox = NonRefCheckbox;
var NonRefCheckboxV2 = /** @class */ (function (_super) {
    __extends(NonRefCheckboxV2, _super);
    function NonRefCheckboxV2(value, onChange, label) {
        var _this = _super.call(this, "label") || this;
        _this.value = value;
        _this.input = new GenericComponent_1.GenericComponent("input")
            .AddClass("uk-checkbox")
            .Attribute("type", "checkbox");
        if (value)
            _this.input.Attribute("checked");
        else
            _this.input.RemoveAttribute("checked");
        _this.element.addEventListener('click', function (e) {
            onChange(_this.input.element.checked);
        });
        _this.Append(_this.input);
        if (label)
            _this.Append(new TextLabel_1.TextLabel(" " + label));
        return _this;
    }
    NonRefCheckboxV2.prototype.Refresh = function () {
        this.input.element.checked = this.value;
    };
    return NonRefCheckboxV2;
}(ComponentBase_1.ComponentBase));
exports.NonRefCheckboxV2 = NonRefCheckboxV2;
