"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.MultilineInput = void 0;
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var Ref_1 = require("../../Core/Ref");
var MultilineInput = /** @class */ (function (_super) {
    __extends(MultilineInput, _super);
    function MultilineInput(ref) {
        if (ref === void 0) { ref = new Ref_1.Ref(""); }
        var _this = _super.call(this, "textarea", ref) || this;
        _this.Class(MultilineInput.DefaultCssClasses);
        _this.element.addEventListener('input', function (e) {
            _this.ref.value = e.target.value;
        });
        _this.Refresh();
        return _this;
    }
    MultilineInput.prototype.Placeholder = function (placeholder) {
        this.element.setAttribute("placeholder", placeholder);
        return this;
    };
    MultilineInput.prototype.Readonly = function () {
        this.Attribute("readonly");
        return this;
    };
    MultilineInput.prototype.Rows = function (count) {
        this.Attribute("rows", count);
        return this;
    };
    MultilineInput.prototype.Refresh = function () {
        this.element.value = this.ref.value;
        if (this.showThatChanged)
            this.ref.HasChanged
                ? this.element.classList.add("tf-value-changed")
                : this.element.classList.remove("tf-value-changed");
    };
    MultilineInput.Name = "MultilineInput";
    MultilineInput.DefaultCssClasses = "";
    return MultilineInput;
}(RefComponentBase_1.RefComponentBase));
exports.MultilineInput = MultilineInput;
