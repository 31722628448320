"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.TableHeader = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var TableHeaderRow_1 = require("./TableHeaderRow");
var TableHeader = /** @class */ (function (_super) {
    __extends(TableHeader, _super);
    function TableHeader(headers) {
        var _this = _super.call(this, "thead") || this;
        if (headers)
            _this.Append(new TableHeaderRow_1.TableHeaderRow(headers));
        return _this;
    }
    return TableHeader;
}(ComponentBase_1.ComponentBase));
exports.TableHeader = TableHeader;
