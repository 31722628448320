import { Checkbox, Collection, Div, Label, Link, NumberInput } from "@tblabs/truffle";
import { Markdown } from "./Components/Markdown";
import { TileData } from "./Models/TileData";
import { User } from "./User";

export class Tile extends Div
{
    constructor(tile: TileData, _user: User, allTiles: Collection<TileData>)
    {
        super();
        this.Class("Tile")
            .PositionRelative()
            .Background(tile.Color);

        this.Append(
            new Markdown(tile.Title.value).Class("header"),
            new Div().Text(tile.Summary.value).Class("body"),
        );


        if (_user.Is("Reader"))
        {
            this
                .CursorPointer()
                .OnClick(() =>
                {
                    window.location.hash = "/view/" + tile.Id;
                });
        }

        if (_user.Is("Owner"))
        {
            tile.Order.ClearHandlers().OnChange(v=>allTiles.Call(_=>_))

            this.Append(
                new Checkbox(tile.IsSelected).PositionAbsolute().Top(4).Left(4),
                new Label(tile.Order).PositionAbsolute().Top(4).Right(4).Color("#aaa").FontSize(12).Background("#eee").Padding(3),
                // new NumberInput(tile.Order).Width(48)
                // .PositionAbsolute().Top(4).Right(4)
                // .Color("#aaa").FontSize(12).Background("#eee").Padding(3)
                // ,
            )

            if (_user.Can("ReadTile"))
                this.Append(
                    new Link("view").NoDecorationOnHover()
                        .OnClick(() => window.location.hash = "/view/" + tile.Id),
                );

            if (_user.Can("EditTile"))
                this.Append(
                    new Link("edit").NoDecorationOnHover().MarginLeft(12)
                        .OnClick(() => window.location.hash = "/edit/" + tile.Id),
                );
        }
    }
}
