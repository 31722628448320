import { $, Button, ModalWindow, Ref, Snack, Spinner, TextInput , Image, Div} from "@tblabs/truffle";
import { AuthService } from "./AuthService";
import { Delay } from "./TileEdit";

export class BlueButton extends Button
{
    constructor(label = "Button", onClick?: () => void)
    {
        super(label, onClick);
        this.Class("uk-button uk-button-primary uk-button-big");
    }
}

export class LoginWindow extends ModalWindow
{
    private pass = new Ref<string>("").Storable("password");
    private loginButton = new BlueButton("Jump in", async () => await this.TryLoginAndGoToListOnSuccess());
    private readonly spinner = new Div("spinner").Hide().FloatLeft();

    constructor(private _auth: AuthService)
    {
        super("Login");
        this.NoCloseButton()

        this.AddContent(
            new TextInput(this.pass).Placeholder("Password only")
                .OnEnterKeyPressed(async () => await this.TryLoginAndGoToListOnSuccess())
        );

        this.AddToFooter(
            this.loginButton, 
            this.spinner)

        this.OpenWindow();
    }
    
    private async TryLoginAndGoToListOnSuccess()
    {
        this.spinner.Show()
        const loginResult = await this._auth.TryLogin(this.pass.value);
        // await Delay(1999)
        // console.log(loginResult);
        if (loginResult.LoginSuccess)
        {
            this.CloseWindow();
            window.location.hash = "/list";
        }
        else
        {
            this.spinner.Hide()
            new Snack("Could not login", loginResult.Message);
        }
    }
}
