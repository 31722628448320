import { Div, Label, Link, Span } from "@tblabs/truffle";
import { Markdown } from "./Components/Markdown";
import { TilesStorage } from "./TilesStorage";


export class TileView extends Div
{
    private readonly spinner = new Div("spinner");
    constructor(private tileId: string, private _storage: TilesStorage)
    {
        super("TileView");
        this.Append(this.spinner)
    }

    protected async OnAppend(): Promise<void>
    {

        const tile = await this._storage.Get(this.tileId)
        const tileContent = await this._storage.GetContent(this.tileId)
        this.spinner.RemoveSelf()
        this.Append(
            new Link("↩").FontSize(32).OnClick(() => window.location.hash = "/list")
        )

        if (!tile)
        {
            this.Append("Not found");
            return;
        }

        this.Append(
            new Markdown(tile.Title.value).Class("title"),
            new Markdown(tile.Summary.value).Class("summary"),
        );

        switch (tile.Format.value)
        {
            case "txt":
                this.Append(
                    new Span(tileContent).Class("content")
                );
                break;
            case "html":
                this.Append(
                    new Span().Html(tileContent).Class("content")
                );
                break;
            case "md":
                this.Append(
                    new Markdown(tileContent).Class("content")
                );
                break;
        }
    }
}
