import { Div, ModalWindow, Range, Ref, Label } from "@tblabs/truffle";
import { ColorObj } from "./ColorObj";
import { PrimaryButton } from "./PrimaryButton";

export class ColorPicker extends ModalWindow
{
    private onColorSelect;
    public OnColorSelect(callback)
    {
        this.onColorSelect = callback;
        return this;
    }
    private ColorObjToString(c: ColorObj)
    {
        return `rgba(${c.R},${c.G},${c.B},${c.A})`;
    }
    private StringToColorObj(color: string): ColorObj
    {
        if (color.startsWith("rgba"))
        {
            color = color.replace("rgba(", "").replace(")", "").replace(/\s+/g, "");

            const parts = color.split(",");

            const r = parseInt(parts[0]);
            const g = parseInt(parts[1]);
            const b = parseInt(parts[2]);
            const a = parseFloat(parts[3]);

            return new ColorObj(r, g, b, a);
        }
        else return new ColorObj();
    }
    constructor(initial: string)
    {
        super("Choose color", true, false, true);

        const box = new Div().WidthAuto().Height(64).Border(1, "#aaa");
        let colorObj = this.StringToColorObj(initial);
        let boxColor = this.ColorObjToString(colorObj);
        const SetBoxColor = (mod: (c: ColorObj) => void) =>
        {
            mod(colorObj);
            boxColor = this.ColorObjToString(colorObj);
            box.Background(boxColor);
        };

        const r = new Ref<number>(colorObj.R).OnChange(v => SetBoxColor(c => c.R = v));
        const g = new Ref<number>(colorObj.G).OnChange(v => SetBoxColor(c => c.G = v));
        const b = new Ref<number>(colorObj.B).OnChange(v => SetBoxColor(c => c.B = v));
        const a = new Ref<number>(colorObj.A).OnChange(v => SetBoxColor(c => c.A = v / 100));
        SetBoxColor(_=>_)

        this.AddContent(
            box, "\n",
            "R", new Range(r, [0, 255]),
            new Label(r).DisplayInlineBlock().Width(32).MarginLeft(4), "\n",
            "G", new Range(g, [0, 255]),
            new Label(g), "\n",
            "B", new Range(b, [0, 255]), new Label(b), "\n",
            "A", new Range(a, [0, 100]),
            new Label(a)
        );

        this.AddToFooter(
            new PrimaryButton("Pick")
                .OnClick(() =>
                {
                    this.onColorSelect?.(boxColor)
                    this.CloseWindow()
                })
        );
        this.OpenWindow();
    }
}
