"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Snack = void 0;
var _1 = require("../Core/$");
var Div_1 = require("./Containers/Div");
var TextLabel_1 = require("./Inputs/TextLabel");
var Snack = /** @class */ (function (_super) {
    __extends(Snack, _super);
    function Snack(label, text, autoCloseTimeout) {
        if (text === void 0) { text = ""; }
        if (autoCloseTimeout === void 0) { autoCloseTimeout = 4500; }
        var _a;
        var _this = _super.call(this) || this;
        // console.log(label, text);
        _this.Class(Snack.DefaultCssClasses);
        _this.Append(new TextLabel_1.TextLabel(label).Class("tf-snack-label"));
        _this.Append(new TextLabel_1.TextLabel(text).Class("tf-snack-text"));
        _this.Append(new TextLabel_1.TextLabel("x").Class("tf-snack-x").OnClick(function () { return _this.Close(); }));
        (_a = (0, _1.$)("body")) === null || _a === void 0 ? void 0 : _a.Append(_this.Element);
        if (autoCloseTimeout)
            setTimeout(function () { return _this.Close(); }, autoCloseTimeout);
        return _this;
    }
    Snack.prototype.Close = function () {
        var _a;
        (_a = (0, _1.$)("body")) === null || _a === void 0 ? void 0 : _a.Remove(this.Element);
    };
    Snack.Name = "Snack";
    Snack.DefaultCssClasses = "tf-snack";
    return Snack;
}(Div_1.Div));
exports.Snack = Snack;
