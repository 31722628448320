"use strict";
exports.__esModule = true;
Array.prototype.ToString = function () {
    var _a;
    return (_a = this.join(", ")) !== null && _a !== void 0 ? _a : "(empty)";
};
Array.prototype.Remove = function (item) {
    var index = this.indexOf(item);
    if (index >= 0) {
        this.splice(index, 1);
        return true;
    }
    return false;
};
