import { Button, DestroyingContentSwitcher, Div, Link, MultilineInput, NumberInput, Ref, Select, SelectOption, Snack, Span, TextInput } from "@tblabs/truffle";
import { ColorPicker } from "./Components/ColorPicker";
import { DangerButton } from "./Components/DangerButton";
import { TilesStorage } from "./TilesStorage";
import { User } from "./User";
import { HtmlEdit, MarkdownEdit } from "./Components/MarkdownEdit";

export function Delay(ms: number)
{
    return new Promise(resolve => setTimeout(resolve, ms));
}

export class TileEdit extends Div
{
    constructor(private tileId: string, private _storage: TilesStorage, private _user: User)
    {
        super("TileEdit");
    }

    private loadingLabel = new Div().Text("⏳ Loading...").MarginLeft(50, "%").MarginTop(120)

    protected async OnAppend(): Promise<void>
    {
        // await Delay(12500)

        if (!this._user.Can("EditTile"))
        {
            this.Append("You can not edit tiles!")
            return;
        }

        this.Append(this.loadingLabel)

        const tile = await this._storage.Get(this.tileId);

        if (!tile)
        {
            this.Append("Not found");
            return;
        }

        const tileContent = await this._storage.GetContent(this.tileId);
        const tileContentRef = new Ref<string>(tileContent);

        this.loadingLabel.RemoveSelf()

        const saveButton = new DangerButton("Save", async (btn) =>
        {
            btn.Label("Saving...").Disabled()
            const savingResult = await this._storage.Save(tile, tileContentRef.value);
            if (savingResult.IsSaved)
            {
                new Snack("Changes saved");
            }
            else new Snack("Saving problem", savingResult.ProblemReason, 9000);

            btn.Label("Save").Enabled()
        });


        this.Append(
            new Link("↩").FontSize(24).Margin(12)
                .OnClick(async () =>
                {
                    if (tileContentRef.value != tileContent)
                    {
                        if (confirm("Save before exit?"))
                        {
                            await this._storage.Save(tile, tileContentRef.value);
                        }
                    }
                    window.location.hash = "/list"
                }),
            saveButton,
            new Button("Set color", () => new ColorPicker(tile.Color.value).OnColorSelect(c => tile.Color.value = c)),
            "Format:", new Select(tile.Format, ["txt", "html", "md"].map(x => new SelectOption(x))).Width(100),
            "Order: ", new NumberInput(tile.Order),
            new TextInput(tile.Title).Class("title"),
            new TextInput(tile.Summary).Class("summary"),

            new DestroyingContentSwitcher(tile.Format)
                .AddContent("txt", () => new MultilineInput(tileContentRef).Rows(40).HeightPercent(100).Class("content"))
                .AddContent("html", () => new HtmlEdit(tileContentRef).Class("content"))
                .AddContent("md", () => new MarkdownEdit(tileContentRef)),
        );
    }
}
