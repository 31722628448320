import { Ref } from "@tblabs/truffle";

export class User
{
    public Can(privilege: string): boolean
    {
        return this.Privileges.includes(privilege);
    }

    public Is(role: string): boolean
    {
        return this.Roles.includes(role);
    }

    public Id = "";
    public Roles: string[] = [];
    public Icon = new Ref<string>("");
    public Privileges: string[] = [];
    public ListOfTilesResourceId = "";

    constructor()
    {

    }

    public LoadFromSessionStorage()
    {
        try 
        {
            this.Id = sessionStorage.getItem('user-id') || "";
            this.Icon.value = sessionStorage.getItem('user-icon') || "😶";
            this.Privileges = JSON.parse(sessionStorage.getItem('user-privileges') || "[]");
            this.Roles = JSON.parse(sessionStorage.getItem('user-roles') || "[]");
            this.ListOfTilesResourceId = sessionStorage.getItem('tiles-list-resource-id') || "";
        }
        catch (error)
        { }

        return this;
    }


    public Save()
    {
        sessionStorage.setItem('user-id', this.Id);
        sessionStorage.setItem('user-icon', this.Icon.value);
        sessionStorage.setItem('user-privileges', JSON.stringify(this.Privileges));
        sessionStorage.setItem('user-roles', JSON.stringify(this.Roles));
        sessionStorage.setItem('tiles-list-resource-id', this.ListOfTilesResourceId);
    }

    public Reset()
    {
        this.Id = "";
        this.Icon.value = "";
        this.Privileges = [];
        this.Roles = [];
        this.ListOfTilesResourceId = "";
        this.Save();
    }
}
