import { IMessage } from "../../Models/StorageMessages/IMessage";

export class StorageReadQuery implements IMessage
{
    public __Name = "Read";

    public App = process.env.APP_NAME || "";
    
    constructor(
        public Id = "") { }
}
