import axios from "axios";
import { IMessage } from "../../Models/StorageMessages/IMessage";
import { User } from "../../User";
import { MessageBusResponse } from "./MessageBusResponse";
import { MessagePackage } from "./MessagePackage";


export class MessageBus
{
    constructor(private _user: User, private target: string) 
    {
        if (!target)
            throw new Error("Target endpoint not defined!")
    }

    public async Send(message: IMessage): Promise<MessageBusResponse>
    {
        const messagePackage = new MessagePackage();
        messagePackage.Message.Id = message.__Name;
        messagePackage.Message.Body = message;
        delete messagePackage.Message.Body.__Name;
        messagePackage.Sender.Id = this._user.Id;
        // console.log('pack', messagePackage);

        return await this.SendMessage(messagePackage);
    }

    private async SendMessage(message: MessagePackage): Promise<MessageBusResponse>
    {
        try
        {
            console.log(`[MessageBus] ${JSON.stringify(message)} --> ${this.target}`);
            const response = await axios.post(this.target, message);

            if (response.status === 200)
            {
                return response.data as MessageBusResponse;
            }

            const result = new MessageBusResponse();
            result.IsSuccess = false;
            result.ErrorMessage = "HTTP ERROR";
            result.ErrorCode = response.status;
            return result;
        }
        catch (error)
        {
            const result = new MessageBusResponse();
            result.IsSuccess = false;
            result.ErrorMessage = "HTTP EXCEPTION";
            // result.ErrorCode = error.response.status;
            return result;
        }
    }
}
