"use strict";
exports.__esModule = true;
exports.Filters = void 0;
var Filters = /** @class */ (function () {
    function Filters() {
    }
    Filters.TextContains = function (filter, field) {
        return filter.value ? field.toLowerCase().includes(filter.value.toLowerCase()) : true;
    };
    return Filters;
}());
exports.Filters = Filters;
