"use strict";
exports.__esModule = true;
exports.SimpleEmitter = void 0;
var SimpleEmitter = /** @class */ (function () {
    function SimpleEmitter() {
        this.handlersNames = [];
        this.handlers = [];
    }
    SimpleEmitter.prototype.AddNamedHandler = function (name, callback) {
        if (!this.handlersNames.includes(name)) {
            this.handlersNames.push(name);
            this.handlers.push(callback);
        }
    };
    Object.defineProperty(SimpleEmitter.prototype, "Subs", {
        get: function () {
            return this.handlers.length;
        },
        enumerable: false,
        configurable: true
    });
    SimpleEmitter.prototype.Clear = function () {
        this.handlers = [];
    };
    SimpleEmitter.prototype.AddHandler = function (callback) {
        this.handlers.push(callback);
    };
    SimpleEmitter.prototype.CallHandlers = function () {
        var val = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            val[_i] = arguments[_i];
        }
        this.handlers.forEach(function (h) { return h.apply(void 0, val); });
    };
    return SimpleEmitter;
}());
exports.SimpleEmitter = SimpleEmitter;
