"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Ref = void 0;
require("./Extensions/ArrayExtensions");
var RefBase_1 = require("./RefBase");
var Ref = /** @class */ (function (_super) {
    __extends(Ref, _super);
    function Ref(_value, allowMasterEmit) {
        if (allowMasterEmit === void 0) { allowMasterEmit = true; }
        var _this = _super.call(this) || this;
        _this._value = _value;
        _this.allowMasterEmit = allowMasterEmit;
        _this.initialValue = _value;
        return _this;
    }
    Ref.prototype.Storable = function (key, storage) {
        if (storage === void 0) { storage = "local"; }
        var stor;
        switch (storage) {
            case "local":
                stor = window.localStorage;
                break;
            case "session":
                stor = window.sessionStorage;
                break;
        }
        var value = stor.getItem(key);
        if (typeof this._value == "string") {
            this.Set((value || ""));
        }
        else if (typeof this._value == "number") {
            this.Set((value || 0));
        }
        else if (typeof this._value == "boolean") {
            this.Set((value || false));
        }
        this.OnChange(function (v) { return stor.setItem(key, v.toString()); });
        return this;
    };
    // public static masterEmitter = new EventEmitter(); // PROBLEMATIC DURING PUBLISH
    Ref.prototype.Set = function (val, excludeControlForRefresh) {
        if (this.value === val)
            return false;
        // this.prevVal = this._value;
        this._value = val;
        this.RefreshSubscribers(excludeControlForRefresh);
        this.emitter.CallHandlers(this._value);
        // if (this.allowMasterEmit)
        // Ref.masterEmitter.emit('set', this, this.prevVal, val)
        return true;
    };
    Ref.prototype.ForceOnChange = function () {
        this.RefreshSubscribers();
        this.emitter.CallHandlers(this._value);
    };
    Object.defineProperty(Ref.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (val) {
            this.Set(val);
        },
        enumerable: false,
        configurable: true
    });
    Ref.prototype.SetValue = function (val, excludeControlForRefresh) {
        return this.Set(val, excludeControlForRefresh);
    };
    Ref.prototype.Reset = function () {
        this.value = this.initialValue;
    };
    Object.defineProperty(Ref.prototype, "HasChanged", {
        get: function () {
            return this._value !== this.initialValue;
        },
        enumerable: false,
        configurable: true
    });
    Ref.prototype.OverrideInitialValue = function (val) {
        this.initialValue = val;
        return this;
    };
    Ref.prototype.Initialize = function (val) {
        this.initialValue = val;
        this._value = val;
        return this;
    };
    Ref.prototype.Clear = function () {
        if (typeof this._value == "string") {
            this.Set("");
        }
        else if (typeof this._value == "number") {
            this.Set(0);
        }
        else if (typeof this._value == "boolean") {
            this.Set(false);
        }
        // else
        // if (typeof this._value == "Date")
        // {
        //     this.Set(new Date() as T);
        // }
        return this;
    };
    Ref.prototype.ToString = function () {
        var _a, _b, _c;
        // console.log('ttt');
        // return `${this._value?.toString()} [${this.watchers?.map(w=>w.Id)?.join(", ")}]`;
        // if (typeof this._value === "string")
        //     return `Ref<${typeof this._value}>#${this.Id}${this.Name ? ":" + this.Name : ""} = "${this._value}" [${this.watchers?.map(w => w.Id)?.join(", ")}]`;
        // else
        // if (typeof this._value === "Date")
        // return `Ref<${typeof this._value}> = "${this._value?.toString()}" [${this.watchers?.map(w => w.Id)?.join(", ")}]`;
        // else
        // if (typeof this._value === "boolean")
        //     return `Ref<${typeof this._value}> = ${this._value?.toString() ? "true" : "false"} [${this.watchers?.map(w => w.Id)?.join(", ")}]`;
        // else
        return "Ref<".concat(typeof this._value, ">#").concat(this.Id).concat(this.Name ? ":" + this.Name : "", " = ").concat((_a = this._value) === null || _a === void 0 ? void 0 : _a.toString(), " [watchers: ").concat(((_c = (_b = this.watchers) === null || _b === void 0 ? void 0 : _b.map(function (w) { return w.Id; })) === null || _c === void 0 ? void 0 : _c.join(", ")) || "none", "][").concat(this.emitter.Subs, " subs]");
    };
    Ref.prototype.Combine = function (anotherRef, operation) {
        var _this = this;
        var result = new Ref(operation(this.value, anotherRef.value));
        this.OnChange(function (v) { return result.value = operation(_this.value, anotherRef.value); });
        anotherRef.OnChange(function (v) { return result.value = operation(_this.value, anotherRef.value); });
        return result;
    };
    Ref.prototype.And = function (anotherRef) {
        return this.Combine(anotherRef, function (a, b) { return a && b; });
    };
    Ref.prototype.Or = function (anotherRef) {
        return this.Combine(anotherRef, function (a, b) { return a || b; });
    };
    Ref.prototype.Plus = function (anotherRef) {
        return this.Combine(anotherRef, function (a, b) { return a + b; });
    };
    Ref.prototype.Minus = function (anotherRef) {
        return this.Combine(anotherRef, function (a, b) { return a - b; });
    };
    Ref.prototype.Multiply = function (anotherRef) {
        return this.Combine(anotherRef, function (a, b) { return a * b; });
    };
    Ref.prototype.Divide = function (anotherRef) {
        return this.Combine(anotherRef, function (a, b) { return a / b; });
    };
    return Ref;
}(RefBase_1.RefBase));
exports.Ref = Ref;
