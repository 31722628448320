"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Button = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button(label, onClick) {
        if (label === void 0) { label = "Button"; }
        var _this = _super.call(this, "button") || this;
        _this.label = label;
        _this.Class(Button.DefaultCssClasses);
        if (onClick)
            _this.OnClick(onClick);
        _this.element.innerHTML = _this.label;
        return _this;
    }
    Button.prototype.Label = function (label) {
        this.element.innerHTML = label;
        return this;
    };
    Button.Name = "Button";
    Button.DefaultCssClasses = "";
    return Button;
}(ComponentBase_1.ComponentBase));
exports.Button = Button;
